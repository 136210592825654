import React from "react";
import * as Icon from "react-feather";
import moment from "moment"
import createNewBookingEngine from "../views/commonComponents/createNewBookingEngine";


const reservationBookingsChild = [
  {
    id: "NewBooking",
    title: "New",
    type: process.env.REACT_APP_BOOKING_DETAILS_PAGE === "NEW" ? "external-link" : "item",
    icon: <Icon.Circle size={10} />,
    permissions: ["admin", "editor"],
    newTab: process.env.REACT_APP_BOOKING_DETAILS_PAGE === "NEW" ? true : false,
    navLink: process.env.REACT_APP_BOOKING_DETAILS_PAGE === "NEW" ? createNewBookingEngine({add: true, edit: false, reservationId: null, isHref:true}) :"/bookings/create",
  }, 
  {
    id: "ManageBookings",
    title: "Manage",
    type: "item",
    icon: <Icon.Circle size={10} />,
    permissions: ["admin", "editor"],
    navLink: "/booking/listing",
  },    
  {
    id: "waitingList",
    title: "Waiting List",
    type: "item",
    icon: <Icon.Circle size={10} />,
    permissions: ["admin", "editor"],
    navLink: "/booking/waitingList"
  },
  {
    id: "GuestList",
    title: "Guest List",
    type: "item",
    icon: <Icon.Mail size={16} />,
    navLink: `/guests-list/${moment().format("YYYY-MM-DD")}`,
    permissions: ["admin", "editor"],
  },
  {
    id: "packageEnquiries",
    title: "Package Enquiries",
    type: "item",
    icon: <Icon.Circle size={10} />,
    permissions: ["admin", "editor"],
    navLink: "/package/enquiries"
  },
]

const horizontalMenuConfig = 
[
    /* Reports Menu */
    {
      id: "reportsMainmenu",
      title: "Reports",
      type: "dropdown",
      icon: <Icon.Settings size={16} />,
      children: [
        {
          id: "yearlyMenu",
          title: "Yearly ",
          type: "dropdown",
          icon: <Icon.Circle size={10} />,
          children: [
            {
              id: "yearlyReport",
              title: "Sales",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/yearly-report/",
            },  
            {
              id: "staffPerformance",
              title: "Staff Performance",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/staffs-performance"
            },   
            {
              id: "packagePerformanceYearly",
              title: "Package Performance",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/reports/packages/yearly-report"
            },   
            {
              id: "roomTypePerformanceYearly",
              title: "Room Type Performance",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/reports/room-types/yearly-report"
            },
            {
              id: "countryPaxSplit",
              title: "Country Wise",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/country-wise-pax-list"
            },
            {
              id: "checkInReport",
              title: "Check In Report",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/yearly-checkin-report"
            },                 
          ],
        },      
        {
          id: "monthlyMenu",
          title: "Monthly ",
          type: "dropdown",
          icon: <Icon.Circle size={10} />,
          children: [
            {
              id: "monthlyReport",
              title: "Sales",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: `/monthly-report?month=${moment().get("month")}&year=${moment().get("year")}`,
            },
            {
              id: "packagePerformanceMonthly",
              title: "Package  Performance ",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/reports/packages/monthly-report"
            },
            {
              id: "roomTypePerformanceMonthly",
              title: "Room Type Performance",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/reports/room-types/monthly-report"
            },
            {
              id: "monthlyBooking",
              title: "Bookings",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/",
            },    
            {
              id: "monthlyPackages",
              title: "Package info",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/package-report",
            },      
            {
              id: "monthlyEvents",
              title: "Events",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/event-monthly-report",
            },   
          ],
        },
        {
          id: "reservationDailyReports",
          title: "Daily",
          type: "dropdown",
          icon: <Icon.Circle size={10} />,
          children: [         
            {
              id: "dailyBooking",
              title: "Daily Booking",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/daily-booking",
            }, 
            {
              id: "dailySalesReport",
              title: "Check in Sales",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/daily-sales-report",
            },
            {
              id: "BookingBalanceReport",
              title: "Booking Balance Report",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/booking-balance-report"
            },
            {
              id: "dailyCollection",
              title: "Collection",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/daily-collections",
            },              
            {
              id: "dailyRefund",
              title: "Refund",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/"
            },
          ],
        },
        {
          id: "reservationTransactions",
          title: "Others",
          type: "dropdown",
          icon: <Icon.Circle size={10} />,
          children: [
            {
              id: "onlineTransactions",
              title: "E-Payments",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/booking/online-transaction",
            },            
            {
              id: "referralRewards",
              title: "Referral Rewards",
              type: "item",
              icon: <Icon.Circle size={10} />,
              permissions: ["admin", "editor"],
              navLink: "/settings/rewards",
            },   
            {
              id: "customerfeedback",
              title: "Feedback",
              type: "item",
              icon: <Icon.User size={16} />,
              navLink: "/feedback",
              permissions: ["admin", "editor"],
            },       
          ],
        }, 
       
     
      ],
    },


  /* Reservation Menu */
  {
    id: "reservation",
    title: "Reservation",
    type: "item",
    navLink: "/",
    icon: <Icon.Home size={16} />,
    children: [
      {
        id: "reservationDashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Mail size={16} />,
        navLink: "/",
        permissions: ["admin", "editor"],
      }, 
      {
        id: "reservationBookings",
        title: "Bookings",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: reservationBookingsChild
      },  
      {
        id: "customInvoice",
        title: "Custom Invoice",
        type: "item",
        icon: <Icon.Mail size={16} />,
        children: [
          {
            id: "ManageCustomInvoice",
            title: "Manage",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/custom-invoice",
          }, 
          {
            id: "CustomInvoicezitems",
            title: "Set up items",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/custom-invoice-items",
          },    
          {
            id: "CustomInvoiceNew",
            title: "New",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/create-custom-invoice",
          },    
        ],
      }, 
      {
        id: "reservationSchedulers",
        title: "Schedule",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "landTransportAllocation",
            title: "Land Trip",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/land-transport-allocation",
          },
          {
            id: "boatTransportAllocation",
            title: "Boat Trip",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/boat-allocation",
          },             
        ],
      }, 
      {
        id: "reservationPromotions",
        title: "Promotions",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "BuyPromoPackage",
            title: "Buy Promo Package",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/promoPackage",
          }, 
          {
            id: "VoucherCode",
            title: "Voucher Code",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/voucherCode",
          },    
          {
            id: "VoucherList",
            title: "Voucher Code List",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/promo",
          }            
        ],
      },  

      {
        id: "reservationTransactions",
        title: "Transactions",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          // {
          //   id: "onlineTransactions",
          //   title: "E-Payments",
          //   type: "item",
          //   icon: <Icon.Circle size={10} />,
          //   permissions: ["admin", "editor"],
          //   navLink: "/booking/online-transaction",
          // }, 
          {
            id: "KnokOffPayments",
            title: "Knok Off Payments",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/knokPayment",
          },
          // {
          //   id: "referralRewards",
          //   title: "Referral Rewards",
          //   type: "item",
          //   icon: <Icon.Circle size={10} />,
          //   permissions: ["admin", "editor"],
          //   navLink: "/settings/rewards",
          // },         
        ],
      }, 

      // {
      //   id: "reservationUsers",
      //   title: "Users",
      //   type: "dropdown",
      //   icon: <Icon.Circle size={10} />,
      //   children: [
      //     {
      //       id: "RegisteredUsers",
      //       title: "Registered",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/userList",
      //     }, 
      //     {
      //       id: "UnregisteredUsers",
      //       title: "Unregistered",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/unregisteredUserList",
      //     }              
      //   ],
      // }, 
      {
        id: "StaffLeave",
        title: "Staff Leave",
        type: "item",
        icon: <Icon.Mail size={16} />,
        navLink: "/leave",
        permissions: ["admin", "editor"],
      },

        
    ]
  },
  
  /* Calendar Menu */
  {
    id: "calendarMenu",
    title: "Calendars",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    children: [
      {
        id: "RoomPlanner",
        title: "Room Planner",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/calender/room-availability/month",
        permissions: ["admin", "editor"],
      },
      {
        id: "weeklyCalendar",
        title: "Weekly Calendar",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/calender/room-availability/week",
        permissions: ["admin", "editor"],
      },
      {
        id: "blockedRooms",
        title: "Blocked Rooms",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/blocked/rooms",
        permissions: ["admin", "editor"],
      },
      // {
      //   id: "QuickRoom",
      //   title: "Quick Room",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: "/calender/room-availability/today",
      //   permissions: ["admin", "editor"],
      // },  
      {
        id: "ResortEvents",
        title: "Events",
        type: "item",
        icon: <Icon.Mail size={16} />,
        navLink: "/events",
        permissions: ["admin", "editor"],
      },  
    ],
  },

  /* Resort Menu*/
  {
    id: "Resort",
    title: "Customers",
    type: "item",
    navLink: "/",
    icon: <Icon.Home size={16} />,
    children: [      
      // {
      //   id: "resortDashboard",
      //   title: "Dashboard",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: "/resort-dashboard",
      //   permissions: ["admin", "editor"],
      // },
      // {
      //   id: "GuestList",
      //   title: "Guest List",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: `/guests-list/${moment().format("YYYY-MM-DD")}`,
      //   permissions: ["admin", "editor"],
      // },
      {
        id: "reservationUsers",
        title: "Users",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "RegisteredUsers",
            title: "Registered",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/userList",
          }, 
          {
            id: "UnregisteredUsers",
            title: "Unregistered",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/unregisteredUserList",
          }              
        ],
      }, 
      {
        id: "customer",
        title: "Reward",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "customerReward",
            title: "Customer Reward",
            type: "item",
            icon: <Icon.User size={16} />,
            navLink: "/customer-reward",
            permissions: ["admin", "editor"],
          },
          {
            id: "customerSocialShare",
            title: "Referral Settings",
            type: "item",
            icon: <Icon.User size={16} />,
            navLink: "/referral-settings",
            permissions: ["admin", "editor"],
          },
        ]
      },
      {
        id: "feedback",
        title: "Feedback",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          // {
          //   id: "customerfeedback",
          //   title: "Customer Feedback",
          //   type: "item",
          //   icon: <Icon.User size={16} />,
          //   navLink: "/feedback",
          //   permissions: ["admin", "editor"],
          // },
          {
            id: "manageFeedback",
            title: " Question",
            type: "item",
            icon: <Icon.User size={16} />,
            navLink: "/setup/feedback",
            permissions: ["admin", "editor"],
          },
          {
            id: "feedbackCategory",
            title: "Category",
            type: "item",
            icon: <Icon.User size={16} />,
            navLink: "/setup/feedback-category",
            permissions: ["admin", "editor"],
          },
        ]
      },
 
      // {
      //   id: "schedulerMenu",
      //   title: "Scheduler",
      //   type: "dropdown",
      //   icon: <Icon.Calendar size={16} />,
      //   children: [          
          
      //     {
      //       id: "itemstobuy",
      //       title: "Items to Buy",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/booking/itemstobuy",
      //     },
      //     {
      //       id: "foodScheduler",
      //       title: "Arrange F&B ",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/today-fb",
      //     },
      //   ],
      // },
      // {
      //   id: "IslandPayment",
      //   title: "Island Payment",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: "/pay-at-resort",
      //   permissions: ["admin", "editor"],
      // },
      // {
      //   id: "QuickRoom",
      //   title: "Quick Room",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: "/calender/room-availability/today",
      //   permissions: ["admin", "editor"],
      // },  
      // {
      //   id: "CustomersRequests",
      //   title: "Customers Requests",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: "/customers-requests",
      //   permissions: ["admin", "editor"],
      // },       
      // {
      //   id: "ResortEvents",
      //   title: "Events",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: "/events",
      //   permissions: ["admin", "editor"],
      // },    
      // {
      //   id: "AddonList",
      //   title: "Addon List",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: "/",
      //   permissions: ["admin", "editor"],
      // },
      // {
      //   id: "HKMenu",
      //   title: "House Keeping",  
      //   type: "item",  
      //   icon: <Icon.Grid size={16} />,
      //   navLink: "/house-keeping",
      //   permissions: ["admin", "editor"],
      // },
      // {
      //   id: "StaffLeave",
      //   title: "Staff Leave",
      //   type: "item",
      //   icon: <Icon.Mail size={16} />,
      //   navLink: "/leave",
      //   permissions: ["admin", "editor"],
      // },
    ]
    
    
  },

  /* Diving Menu */
  {
    id: "DivingMenu",
    title: "Diving",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "DivingDashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/",
        permissions: ["admin", "editor"],
      },
      {
        id: "DivingCalendar",
        title: "Diving Calendar",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/calender/divingCalender",
        permissions: ["admin", "editor"],
      },
      {
        id: "DivingTeam",
        title: "Schedule Diving",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/transportation/diving-allocation",
      },
      {
        id: "DivingReports",
        title: "Reports",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "DivingDaily",
            title: "Daily Diving",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/",
          }, 
          {
            id: "DivingMonthly",
            title: "Monthly Diving",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/",
          }, 
          {
            id: "DivingYearly",
            title: "Yearly Diving",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/",
          }                
        ],
      },
      {
        id: "Divingettings",
        title: "Settings",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "DivingDepartments",
            title: "Departments",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/resort/divingdepartmentsettings",
            permissions: ["admin", "editor"]
          },                                 
        ],
      },   
    ],
  },
  /* Travel Agents Menu */
  {
    id: "TravelAgentMenu",
    title: "Travel Agents",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "TVDashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/travelagents-dashboard",
        permissions: ["admin", "editor"],
      },
      {
        id: "tapproval",
        title: "Approval",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [         
          {
            id: "approveTravelAgent",
            title: "Registration",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/travelagentsregistration",
          },
          {
            id: "ApproveAgentsAddons",
            title: "Agent Addons",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/agents-approve-addons",
          },
                
        ],
      }, 
      {
        id: "salescommission",
        title: "Sales & Commision",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "Summery",
            title: "Summery",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/agents-comission",
          }, 
          {
            id: "agentCommission",
            title: "Agent Commissions",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/agent-commissions",
          }, 
          {
            id: "AgentAddonSales",
            title: "Agent Addon Sales",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/agents-addons-commission",
          },
          {
            id: "Toplevelagent",
            title: "Top Level Agents",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/agents-toplevelagent",
          }                
        ],
      }, 
      {
        id: "unApprovedBookingList",
        title: "Awaiting Approval",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/unApprovedBookings",
        permissions: ["admin", "editor"],
      },
     
     
    
      {
        id: "walletPayout",
        title: "Payout",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/wallet-payout",
      },
      
      // {
      //   id: "TAReports",
      //   title: "Reports",
      //   type: "dropdown",
      //   icon: <Icon.Circle size={10} />,
      //   children: [
      //     {
      //       id: "CommissionReports",
      //       title: "Commission Reports",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/agents-comission",
      //     }, 
      //     {
      //       id: "AgentAddonSales",
      //       title: "Agent Addon Sales",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/agents-addons-commission",
      //     }              
      //   ],
      // }, 

      {
        id: "TASettings",
        title: "Settings",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "agenFiles",
            title: "Agent Files",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/agentFiles",
            permissions: ["admin", "editor"]
          },
          {
            id: "AgentsPromoImages ",
            title: "Agent Banners",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/agentImages",
          },          
          {
            id: "travelAgentsLevels",
            title: "Agent Level",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/travelagents",
            permissions: ["admin", "editor"],
          },
          {
            id: "agentAddonCategory",
            title: "Agent Addon Category",
            type: "item",
            icon: <Icon.Circle size={16} />,
            navLink: "/activities/agent-addon-category",
            permissions: ["admin", "editor"],                
          },                      
        ],
      },
    ],
  },

  /* Scheduler Menu */
  

  /* Setup Menu */  
  {
    id: "SetupMenu",
    title: "Setup",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
    
      {
        id: "setupPackages",
        title: "Packages",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/packages",
      },
      {
        id: "setupPackages",
        title: "Permission",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/permission",
      },
      // {
      //   id: "setupResortOperatingTime",
      //   title: "Resort Operation Time",
      //   type: "item",
      //   icon: <Icon.Circle size={10} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/setup/resort-operating-time",
      // },
      {
        id: "addon",
        title: "Addons",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "setupMangeaddon",
            title: "Manage",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/activities/addon",
            permissions: ["admin", "editor"],
          },
          {
            id: "addonCategory",
            title: "Category",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/activities/addon-category",
            permissions: ["admin", "editor"],
          },
        ],
      },

      {
        id: "transportation",
        title: "Transportation",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "ManageTrip",
            title: "Manage Trip",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/tripcosts",
          },
          {
            id: "vehicle",
            title: "Vehicles",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/vehicle",
          },
          {
            id: "locations",
            title: "Locations",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/locations",
          },
          {
            id: "category",
            title: "Category Location",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/category",
          },
        ],
      },

   
      {
        id: "charges",
        title: "Surcharges",
        type: "dropdown",
        icon: <Icon.Settings size={16} />,
        children: [
          {
            id: "calender",
            title: "Season Calendar",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/charges/surchargesCalender",
            permissions: ["admin", "editor"],
          },
          {
            id: "surcharges",
            title: "Set Surcharges",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/charges/surcharges",
            permissions: ["admin", "editor"],
          },     
          {
            id: "surchargeDescription",
            title: "Web Description ",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/surcharge-description",
            permissions: ["admin", "editor"],
          },       
        ],
      },

      {
        id: "addtionalCharges",
        title: "Addtional Charges",
        type: "item",
        icon: <Icon.User size={16} />,
        navLink: "/charges/additionalCharges",
        permissions: ["admin", "editor"],
      },
      // {
      //   id: "customer",
      //   title: "Customer",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "customerReward",
      //       title: "Customer Reward",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/customer-reward",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "customerSocialShare",
      //       title: "Referral Settings",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/referral-settings",
      //       permissions: ["admin", "editor"],
      //     },
      //   ]
      // },
      // {
      //   id: "feedback",
      //   title: "Feedback",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "customerfeedback",
      //       title: "Customer Feedback",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/feedback",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "manageFeedback",
      //       title: "Feedback Question",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/setup/feedback",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "feedbackCategory",
      //       title: "Feedback Category",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/setup/feedback-category",
      //       permissions: ["admin", "editor"],
      //     },
      //   ]
      // },
      // {
      //   id: "SetupSettings",
      //   title: "Settings",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "resort",
      //       title: "Resort",
      //       type: "dropdown",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "companies",
      //       title: "Companies",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/general/companies",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "Taxes",
      //       title: "Taxes",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/charges/taxes",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "RoomBlockReason",
      //       title: "Room Block Reasons",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/room-block",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "WhereFind",
      //       title: "Where to Find US",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/booking/bookingsetting",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "TypesofPax",
      //       title: "Types of Pax",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/booking/paxsetting",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "PISettings",
      //       title: "PI Settings",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/setup/perfomanceinvoice",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "EmailSettings",
      //       title: "Email Settings",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/setup/email",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "E-walletSettings",
      //       title: "E-Wallet Settings",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/setup/e-wallet",
      //       permissions: ["admin", "editor"],
      //     },
      //   ],
      // },   
      
      {
        id: "members",
        title: "Admin Users",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "departments",
            title: "Departments",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/users/departments",
            permissions: ["admin", "editor"],
          },
          {
            id: "roles",
            title: "Staff Roles",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/users/roles",
            permissions: ["admin", "editor"],
          },
          {
            id: "users",
            title: "Staffs",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/users",
            permissions: ["admin", "editor"],
          },
          {
            id: "drivers",
            title: "Drivers",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/resort/driverdepartmentsettings",
            permissions: ["admin", "editor"],
          },          
        ],
      },

      {
        id: "rooms",
        title: "Rooms",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "buildings",
            title: "Buildings",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/buildings",
            permissions: ["admin", "editor"],
          },
          {
            id: "beds",
            title: "Beds",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/beds/",
            permissions: ["admin", "editor"],
          },
          {
            id: "amenitiesCategory",
            title: "Amenities Category",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/amenities-category",
            permissions: ["admin", "editor"],
          },
          {
            id: "amenities",
            title: "Room Amenities",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/amenities",
            permissions: ["admin", "editor"],
          },
          {
            id: "roomtypes",
            title: "Room Types",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/room-types",
            permissions: ["admin", "editor"],
          },
          {
            id: "roomnos",
            title: "Rooms Numbers",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/rooms-numbers",
            permissions: ["admin", "editor"],
          },
        ],
      },

      // {
      //   id: "Websites",
      //   title: "Websites",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "facilities",
      //       title: "Resort Facilities",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/resort/facilities",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "restaurants",
      //       title: "Restaurants",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/resort/resturants",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "Meals",
      //       title: "F&B",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/resort/meals",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "packageOptions",
      //       title: "Package Options",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/resort/package-options",
      //     },
      //     {
      //       id: "boatScheduleDescription",
      //       title: "Boat Schedule Description",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/boatschedule-description",
      //     },  
      //   ],
      // },
         
    ],
  },
   /* Setup Menu */  

   /* Settings */
   {
    id: "SettingsMenu",
    title: "Settings",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [

      // {
      //   id: "setupCurrency",
      //   title: "Currency",
      //   type: "item",
      //   icon: <Icon.Circle size={10} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/settings/currency",
      // },
      // {
      //   id: "setupPackages",
      //   title: "Packages",
      //   type: "item",
      //   icon: <Icon.Circle size={10} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/packages",
      // },
      // {
      //   id: "setupResortOperatingTime",
      //   title: "Resort Operation Time",
      //   type: "item",
      //   icon: <Icon.Circle size={10} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/setup/resort-operating-time",
      // },
      // {
      //   id: "transportation",
      //   title: "Transportation",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "ManageTrip",
      //       title: "Manage Trip",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/transportation/tripcosts",
      //     },
      //     {
      //       id: "vehicle",
      //       title: "Vehicles",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/transportation/vehicle",
      //     },
      //     {
      //       id: "locations",
      //       title: "Locations",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/transportation/locations",
      //     },
      //     {
      //       id: "category",
      //       title: "Category Location",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/transportation/category",
      //     },
      //   ],
      // },

      // {
      //   id: "addon",
      //   title: "Add On",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "setupMangeaddon",
      //       title: "Manage",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/activities/addon",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "addonCategory",
      //       title: "Category",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/activities/addon-category",
      //       permissions: ["admin", "editor"],
      //     },
      //   ],
      // },

      // {
      //   id: "charges",
      //   title: "Surcharges",
      //   type: "dropdown",
      //   icon: <Icon.Settings size={16} />,
      //   children: [
      //     {
      //       id: "calender",
      //       title: "Season Calendar",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/charges/surchargesCalender",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "surcharges",
      //       title: "Set Surcharges",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/charges/surcharges",
      //       permissions: ["admin", "editor"],
      //     },     
      //     {
      //       id: "surchargeDescription",
      //       title: "Surcharge Description (website)",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/surcharge-description",
      //       permissions: ["admin", "editor"],
      //     },       
      //   ],
      // },

      // {
      //   id: "addtionalCharges",
      //   title: "Addtional Charges",
      //   type: "item",
      //   icon: <Icon.User size={16} />,
      //   navLink: "/charges/additionalCharges",
      //   permissions: ["admin", "editor"],
      // },
      // {
      //   id: "customer",
      //   title: "Customer",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "customerReward",
      //       title: "Customer Reward",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/customer-reward",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "customerSocialShare",
      //       title: "Referral Settings",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/referral-settings",
      //       permissions: ["admin", "editor"],
      //     },
      //   ]
      // },
      // {
      //   id: "feedback",
      //   title: "Feedback",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "customerfeedback",
      //       title: "Customer Feedback",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/feedback",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "manageFeedback",
      //       title: "Feedback Question",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/setup/feedback",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "feedbackCategory",
      //       title: "Feedback Category",
      //       type: "item",
      //       icon: <Icon.User size={16} />,
      //       navLink: "/setup/feedback-category",
      //       permissions: ["admin", "editor"],
      //     },
      //   ]
      // },
      {
        id: "resort",
        title: "Resort",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          // {
          //   id: "resort",
          //   title: "Resort",
          //   type: "dropdown",
          //   icon: <Icon.User size={16} />,
          //   navLink: "/",
          //   permissions: ["admin", "editor"],
          // },
          {
            id: "setupResortOperatingTime",
            title: "Operating Dates",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/setup/resort-operating-time",
          },
          {
            id: "companies",
            title: "Companies",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/general/companies",
            permissions: ["admin", "editor"],
          },
          {
            id: "accountsCompanies",
            title: "Accounts Companies",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/accounts/companies",
            permissions: ["admin", "editor"],
          },

          // {
          //   id: "Taxes",
          //   title: "Taxes",
          //   type: "item",
          //   icon: <Icon.Circle size={10} />,
          //   navLink: "/charges/taxes",
          //   permissions: ["admin", "editor"],
          // },
          // {
          //   id: "RoomBlockReason",
          //   title: "Room Block Reasons",
          //   type: "item",
          //   icon: <Icon.User size={16} />,
          //   navLink: "/room-block",
          //   permissions: ["admin", "editor"],
          // },
          // {
          //   id: "WhereFind",
          //   title: "Where to Find US",
          //   type: "item",
          //   icon: <Icon.Circle size={10} />,
          //   navLink: "/booking/bookingsetting",
          //   permissions: ["admin", "editor"],
          // },
          {
            id: "TypesofPax",
            title: "Types of Pax",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/booking/paxsetting",
            permissions: ["admin", "editor"],
          },
          {
            id: "PISettings",
            title: "PI Settings",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/setup/perfomanceinvoice",
            permissions: ["admin", "editor"],
          },
          {
            id: "EmailSettings",
            title: "Email Settings",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/setup/email",
            permissions: ["admin", "editor"],
          },
          {
            id: "E-walletSettings",
            title: "E-Wallet Settings",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/setup/e-wallet",
            permissions: ["admin", "editor"],
          },
        ],
      },  
      // {
      //   id: "Bookings",
      //   title: "Setup Bookings",
      //   type: "collapse",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "lastMinute",
      //       title: "Last Minute",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/setup/last-minute-booking-day",
      //     }
      //   ]
      // },
      {
        id: "setupCurrency",
        title: "Currency",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/settings/currency",
      }, 
      {
        id: "Taxes",
        title: "Taxes",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/charges/taxes",
        permissions: ["admin", "editor"],
      },
      {
        id: "RoomBlockReason",
        title: "Room Block Reasons",
        type: "item",
        icon: <Icon.User size={16} />,
        navLink: "/room-block",
        permissions: ["admin", "editor"],
      },
      {
        id: "WhereFind",
        title: "Where to Find US",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/bookingsetting",
        permissions: ["admin", "editor"],
      },
      // {
      //   id: "members",
      //   title: "Admin Users",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "departments",
      //       title: "Departments",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/users/departments",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "roles",
      //       title: "Staff Roles",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/users/roles",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "users",
      //       title: "Staffs",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/users",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "drivers",
      //       title: "Drivers",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/resort/driverdepartmentsettings",
      //       permissions: ["admin", "editor"],
      //     },          
      //   ],
      // },

      // {
      //   id: "rooms",
      //   title: "Rooms",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "buildings",
      //       title: "Buildings",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/buildings",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "beds",
      //       title: "Beds",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/beds/",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "amenitiesCategory",
      //       title: "Amenities Category",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/amenities-category",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "amenities",
      //       title: "Room Amenities",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/amenities",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "roomtypes",
      //       title: "Room Types",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/room-types",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "roomnos",
      //       title: "Rooms Numbers",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/rooms-numbers",
      //       permissions: ["admin", "editor"],
      //     },
      //   ],
      // },

      // {
      //   id: "Websites",
      //   title: "Websites",
      //   type: "dropdown",
      //   icon: <Icon.User size={16} />,
      //   children: [
      //     {
      //       id: "facilities",
      //       title: "Resort Facilities",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/resort/facilities",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "restaurants",
      //       title: "Restaurants",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/resort/resturants",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "Meals",
      //       title: "F&B",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       navLink: "/resort/meals",
      //       permissions: ["admin", "editor"],
      //     },
      //     {
      //       id: "packageOptions",
      //       title: "Package Options",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/resort/package-options",
      //     },
      //     {
      //       id: "boatScheduleDescription",
      //       title: "Boat Schedule Description",
      //       type: "item",
      //       icon: <Icon.Circle size={10} />,
      //       permissions: ["admin", "editor"],
      //       navLink: "/boatschedule-description",
      //     },  
      //   ],
      // },
         
    ],
  },
    /* Settings */
    {
      id: "Websites",
      title: "Websites",
      type: "dropdown",
      icon: <Icon.Settings size={16} />,
      children: [
        {
          id: "facilities",
          title: "Resort Facilities",
          type: "item",
          icon: <Icon.Circle size={10} />,
          navLink: "/resort/facilities",
          permissions: ["admin", "editor"],
        },
        {
          id: "restaurants",
          title: "Restaurants",
          type: "item",
          icon: <Icon.Circle size={10} />,
          navLink: "/resort/resturants",
          permissions: ["admin", "editor"],
        },
        {
          id: "Meals",
          title: "F&B",
          type: "item",
          icon: <Icon.Circle size={10} />,
          navLink: "/resort/meals",
          permissions: ["admin", "editor"],
        },
        {
          id: "packageOptions",
          title: "Package Options",
          type: "item",
          icon: <Icon.Circle size={10} />,
          permissions: ["admin", "editor"],
          navLink: "/resort/package-options",
        },
        {
          id: "boatScheduleDescription",
          title: "Boat Schedule Description",
          type: "item",
          icon: <Icon.Circle size={10} />,
          permissions: ["admin", "editor"],
          navLink: "/boatschedule-description",
        },  
      ],
    },

/* Old Menu Links
  {
    id: "booking",
    title: "Booking",
    type: "dropdown",
    icon: <Icon.Grid size={16} />,
    children: [
      {
        id: "newbooking",
        title: "Add New",
        type: "item",
        icon: <Icon.Mail size={16} />,
        navLink: "/bookings/create",
        permissions: ["admin", "editor"],
      },
      {
        id: "bookingList",
        title: "Booking List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/listing",
        permissions: ["admin", "editor"],
      },     
      {
        id: "guestList",
        title: "Today's Guest List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: `/guests-list/${moment().format("YYYY-MM-DD")}`,
        permissions: ["admin", "editor"],
      },
      {
        id: "payatresort",
        title: "Pay at Resort List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/pay-at-resort",
        permissions: ["admin", "editor"],
      },
      {
        id: "waitingList",
        title: "Waiting List",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/waitingList",
        permissions: ["admin", "editor"],
      },
      {
        id: "eghtTransaction",
        title: "Online Transaction",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/eghl-transaction",
        permissions: ["admin", "editor"],
      },
      {
        id: "promoPackage",
        title: "Promo Packages",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/promoPackage",
        permissions: ["admin", "editor"]
      },
      {
        id: "knokPayment",
        title: "Knok off payment ",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/knokPayment",
        permissions: ["admin", "editor"]
      },

      // {
      //   id: "bookingDetails",
      //   title: "Booking Details",
      //   type: "item",
      //   icon: <Icon.Circle size={10} />,
      //   navLink: "/booking/details",
      //   permissions: ["admin", "editor"],
      // },
    ],
    //   {
    //     id: "listbooking",
    //     title: "List Booking",
    //     type: "item",
    //     icon: <Icon.MessageSquare size={16} />,
    //     navLink: "/chat",
    //     permissions: ["admin", "editor"]
    //   },
    // {
    //   id: "todo",
    //   title: "Todo",
    //   type: "item",
    //   icon: <Icon.CheckSquare size={16} />,
    //   navLink: "/todo/:filter",
    //   filterBase: "/todo/all",
    //   permissions: ["admin", "editor"]
    // },
    // {
    //   id: "calendar",
    //   title: "Calendar",
    //   type: "item",
    //   icon: <Icon.Calendar size={16} />,
    //   navLink: "/calendar",
    //   permissions: ["admin", "editor"]
    // },
    // {
    //   id: "eCommerce",
    //   title: "Ecommerce",
    //   type: "dropdown",
    //   icon: <Icon.ShoppingCart size={16} />,
    //   children: [
    //     {
    //       id: "shop",
    //       title: "Shop",
    //       type: "item",
    //       icon: <Icon.Circle size={10} />,
    //       navLink: "/ecommerce/shop",
    //       permissions: ["admin", "editor"]
    //     },
    //     {
    //       id: "detail",
    //       title: "Product Detail",
    //       type: "item",
    //       icon: <Icon.Circle size={10} />,
    //       navLink: "/ecommerce/product-detail",
    //       permissions: ["admin", "editor"]
    //     },
    //     {
    //       id: "wishList",
    //       title: "Wish List",
    //       type: "item",
    //       icon: <Icon.Circle size={10} />,
    //       navLink: "/ecommerce/wishlist",
    //       permissions: ["admin", "editor"]
    //     },
    //     {
    //       id: "checkout",
    //       title: "Checkout",
    //       type: "item",
    //       icon: <Icon.Circle size={10} />,
    //       navLink: "/ecommerce/checkout",
    //       permissions: ["admin", "editor"]
    //     }
    //   ]
    // },
    // {
    //   id: "usersApp",
    //   title: "User",
    //   type: "dropdown",
    //   icon: <Icon.User size={16} />,
    //   children: [
    //     {
    //       id: "userList",
    //       title: "List",
    //       type: "item",
    //       icon: <Icon.Circle size={10} />,
    //       navLink: "/app/user/list",
    //       permissions: ["admin", "editor"]
    //     },
    //     {
    //       id: "userView",
    //       title: "View",
    //       type: "item",
    //       icon: <Icon.Circle size={10} />,
    //       navLink: "/app/user/view",
    //       permissions: ["admin", "editor"]
    //     },
    //     {
    //       id: "userEdit",
    //       title: "Edit",
    //       type: "item",
    //       icon: <Icon.Circle size={10} />,
    //       navLink: "/app/user/edit",
    //       permissions: ["admin", "editor"]
    //     }
    //   ]
    // }
    // ]
  },

  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    children: [
      {
        id: "monthly",
        title: "Monthly",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/calender/room-availability/month",
        permissions: ["admin", "editor"],
      },
      {
        id: "weekly",
        title: "Weekly",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/calender/room-availability/week",
        permissions: ["admin", "editor"],
      },
      {
        id: "Today",
        title: "Quick Room",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/calender/room-availability/today", //todo route has to be updated
        permissions: ["admin", "editor"],
      },
      {
        id: "DivingCalendar",
        title: "Diving Calendar",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/calender/divingCalender", //todo route has to be updated
        permissions: ["admin", "editor"],
      },


    ],
  },

  {
    id: "scheduler",
    title: "Scheduler",
    type: "dropdown",
    icon: <Icon.Calendar size={16} />,
    children: [
      {
        id: "landTransportAllocation",
        title: "Land Transport Allocation",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/transportation/land-transport-allocation",
      },
      {
        id: "boatTransportAllocation",
        title: "Boat Transport Allocation",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/transportation/boat-allocation",
      },
      {
        id: "divingAllocation",
        title: "Diving Allocation",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/transportation/diving-allocation",
      },
      {
        id: "itemstobuy",
        title: "Items to Buy",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/booking/itemstobuy",
      },
      {
        id: "foodScheduler",
        title: "Food ",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/today-fb",
      },
    ],
  },
  
  {
    id: "userTypes",
    title: "Travel Agents",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "unApprovedBookingList",
        title: "Awaiting Approval",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/unApprovedBookings",
        permissions: ["admin", "editor"],
      },
          {
            id: "approveTravelAgent",
            title: "Agent Registration",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/travelagentsregistration",
          },
          {
            id: "ApproveAgentsAddons",
            title: "Approve Addons",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/agents-approve-addons",
          },
          {
            id: "AgentsAddonsCommission ",
            title: "Addons Commission ",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/agents-addons-commission",
          },
          {
            id: "agenFiles",
            title: "Agent Files",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/agentFiles",
            permissions: ["admin", "editor"]
          },
          {
            id: "travelAgentsCategory",
            title: "Agent Level",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/travelagents",
            permissions: ["admin", "editor"],
          },
          {
            id: "agentAddon",
            title: "Agent Add On",
            type: "item",
            icon: <Icon.Circle size={16} />,
            navLink: "/activities/agent-addon-category",
            permissions: ["admin", "editor"],                
          },
           
    ],
  },
  {
    id: "settingusers",
    title: "Users",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [

      {
        id: "userList",
        title: "Users List",
        type: "item",
        icon: <Icon.Settings size={10} />,
        navLink: "/userList",
        permissions: ["admin", "editor"],
      }

    ],
  },
  {
    id: "pricesettings",
    title: "Settings",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "Packages",
        title: "Packages",
        type: "item",
        icon: <Icon.User size={10} />,
        navLink: "/packages",
        permissions: ["admin", "editor"],
      },


      {
        id: "addon",
        title: "Manage Addons",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/activities/addon",
        permissions: ["admin", "editor"],
      },
      {
        id: "tripcosts",
        title: "Manage Tripcosts",
        type: "item",
        icon: <Icon.Circle size={10} />,
        permissions: ["admin", "editor"],
        navLink: "/transportation/tripcosts",
      },

      {
        id: "charges",
        title: "Surcharges",
        type: "dropdown",
        icon: <Icon.Settings size={16} />,
        children: [
          {
            id: "surcharges",
            title: "Surcharges",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/charges/surcharges",
            permissions: ["admin", "editor"],
          },
          {
            id: "calender",
            title: "Season Calendar",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/charges/surchargesCalender",
            permissions: ["admin", "editor"],
          },
        ],
      },
      {
        id: "additionalCharges",
        title: "Additional Charges",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/charges/additionalCharges",
        permissions: ["admin", "editor"],
      },
      {
        id: "currency",
        title: "Currency",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/settings/currency",
        permissions: ["admin", "editor"],
      },
      {
        id: "promoCode",
        title: "Promo Code",
        type: "item",
        navLink: "/promo",
        icon: <Icon.User size={16} />
      },
      // {
      //   id: "voucher",
      //   title: "Voucher",
      //   type: "item",
      //   navLink: "/vouchers",
      //   icon: <Icon.User size={16} />
      // },
      {
        id: "projectSettings",
        title: "General Settings",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/general/settings",
        permissions: ["admin", "editor"]
      }
      
     
    ],
  },

  {
    id: "settings",
    title: "Set Up",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "resort",
        title: "Resort",
        type: "dropdown",
        icon: <Icon.User size={16} />,
      },
      {
        id: "charta/c",
        title: "Chart of A/C",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "companies",
            title: "Companies",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/general/companies",
            permissions: ["admin", "editor"],
          },
          {
            id: "Taxes",
            title: "Taxes",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/charges/taxes",
            permissions: ["admin", "editor"],
          },
        ],
      },
      {
        id: "members",
        title: "Admin Users",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "departments",
            title: "Departments",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/users/departments",
            permissions: ["admin", "editor"],
          },
          {
            id: "roles",
            title: "Staff Roles",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/users/roles",
            permissions: ["admin", "editor"],
          },
          {
            id: "users",
            title: "Staffs",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/users",
            permissions: ["admin", "editor"],
          },
          {
            id: "leave",
            title: "Leave List",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/leave",
            permissions: ["admin", "editor"],
          }
        ],
      },
      {
        id: "rooms",
        title: "Rooms",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "buildings",
            title: "Buildings",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/buildings",
            permissions: ["admin", "editor"],
          },
          {
            id: "beds",
            title: "Beds",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/beds/",
            permissions: ["admin", "editor"],
          },
          {
            id: "amenities",
            title: "Room Amenities",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/amenities",
            permissions: ["admin", "editor"],
          },
          {
            id: "roomtypes",
            title: "Room Types",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/room-types",
            permissions: ["admin", "editor"],
          },
          {
            id: "roomnos",
            title: "Room Numbers",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/rooms-numbers",
            permissions: ["admin", "editor"],
          },
        ],
      },
      {
        id: "hotelAmenities",
        title: "Hotel amenities",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "facilities",
            title: "Resort Facilities",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/resort/facilities",
            permissions: ["admin", "editor"],
          },
          {
            id: "restaurants",
            title: "Restaurants",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/resort/resturants",
            permissions: ["admin", "editor"],
          },
        ],
      },
      {
        id: "foodandbeverages",
        title: "Food and Beverages",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "Meals",
            title: "Meals",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/resort/meals",
            permissions: ["admin", "editor"],
          },
        ],
      },
      {
        id: "diving",
        title: "Diving",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "divingdepartment",
            title: "Department ",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/resort/divingdepartmentsettings",
            permissions: ["admin", "editor"],
          },
          {
            id: "divingcourse",
            title: "Diving Course",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/resort/divingcourse",
            permissions: ["admin", "editor"],
          },
        ],
      },
      {
        id: "addon",
        title: "Add On",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "addonCategory",
            title: "Category",
            type: "item",
            icon: <Icon.Circle size={10} />,
            navLink: "/activities/addon-category",
            permissions: ["admin", "editor"],
          },
        ],
      },
     
      {
        id: "transportation",
        title: "Transportation",
        type: "dropdown",
        icon: <Icon.User size={16} />,
        children: [
          {
            id: "category",
            title: "Category Location",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/category",
          },
          {
            id: "locations",
            title: "Location",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/locations",
          },
          {
            id: "vehicle",
            title: "Vehicle",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/transportation/vehicle",
          },
        ],
      },
     
      {
        id: "maintenanceCategory",
        title: "Maintenance",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/maintenance",
        permissions: ["admin", "editor"],
      }
    ],
  },

  {
    id: "siteconfig",
    title: "Site Config",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "bookingpage",
        title: "Booking Page",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "bookingPage",
            title: "Booking",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/booking/bookingsetting",
          },
          {
            id: "typeOfPax",
            title: "Type Of Pax",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/booking/paxsetting",
          },
          {
            id: "perfomanceInvoiceSettings",
            title: "Perfomance Invoice",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            //navLink: "/setup/perfomanceinvoice"
          },
          {
            id: "bookingEmailSetting",
            title: "Booking Email",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            //navLink: "/setup/email"
          },
        ],
      },
    ],
  },

  {
    id: "reports_menu",
    title: "Reports",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "yearlyMenu",
        title: "Yearly ",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "yearlyReport",
            title: "Yearly Report",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/yearly-report/",
          },                   
        ],
      },      
      {
        id: "monthlyMenu",
        title: "Monthly ",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "monthlyReport",
            title: "Monthly Report",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: `/monthly-report?month=${moment().get("month")}&year=${moment().get("year")}`,
          },
          {
            id: "monthlyBooking",
            title: "Monthly Booking",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "#",
          },          
        ],
      },
      {
        id: "dailyMenu",
        title: "Daily ",
        type: "dropdown",
        icon: <Icon.Circle size={10} />,
        children: [
          {
            id: "dailyBooking",
            title: "Daily Booking",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/",
          }, 
          {
            id: "dailyCollection",
            title: "Daily Collection",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/daily-collections",
          },    
          {
            id: "dailyRefund",
            title: "Daily Refund",
            type: "item",
            icon: <Icon.Circle size={10} />,
            permissions: ["admin", "editor"],
            navLink: "/daily-refund"
          },
        ],
      },  
    ],
  },
 */
 
];

export default horizontalMenuConfig;
